import { Article } from '@/Article';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { getNavLink, navLinks } from '@/layout/NavLinks';
import { miniCardStyles } from '@/MiniCard';
import { graphql, useStaticQuery } from 'gatsby';
import {
    GatsbyImage,
    getImage,
    IGatsbyImageData,
    ImageDataLike,
    StaticImage,
} from 'gatsby-plugin-image';
import { Fragment } from 'react';

const OurTreatments = () => {
    type DataType = {
        allFile: {
            nodes: {
                image: ImageDataLike;
                name: string;
            }[];
        };
    };

    const data: DataType = useStaticQuery(graphql`
        query ourTreatmentPageImages {
            allFile(filter: { relativeDirectory: { eq: "treatments" } }) {
                nodes {
                    image: childImageSharp {
                        gatsbyImageData(
                            width: 328
                            height: 317
                            transformOptions: { cropFocus: CENTER }
                        )
                    }
                    name
                }
            }
        }
    `);

    // get all the images from treatments folder, file names are same as service link slug
    const treatmentImages = data.allFile.nodes.map(node => ({
        img: getImage(node.image) as IGatsbyImageData,
        filename: node.name,
    }));

    // get the image matching the filename which service link slug
    // botox-neurotoxin the nav link is /treatments/botox-neurotoxin/
    // keep the nav link text as the service title
    // some titles case have to be manually changed
    const treatmentsInfo = navLinks
        .filter(data => data.text === 'treatments')[0]
        .links?.map(info => ({
            ...treatmentImages.filter(img => info.link.split('/')[2] === img.filename)[0],
            title:
                info.text === 'pdo thread lift'
                    ? 'PDO thread lift'
                    : info.text === 'prf & prp, prf gel naturelle'
                    ? 'PRF & PRP, PRF Gel Naturelle'
                    : info.text === 'mesotherapy / bio revitalization'
                    ? 'Mesotherapy / Bio Revitalization'
                    : info.text === 'bodysculp fat reduction'
                    ? 'bodySculp fat reduction'
                    : info.text === 'sylfirmx rf microneedling skin tightening'
                    ? 'sylfirmX rF microneedling skin tightening'
                    : info.text,
        }));

    return (
        <Fragment>
            <CommonHero heading="Comprehensive Beauty Treatments - Beauty Religion">
                <StaticImage
                    src="../../assets/images/treatments-hero.jpg"
                    alt="woman behind a plant"
                    width={846}
                    height={430}
                    loading="eager"
                />
            </CommonHero>
            <Article miniCardsGrid big>
                {treatmentsInfo?.map((treatment, i) => (
                    <CLink key={i} to={getNavLink(treatment.title)} css={miniCardStyles}>
                        <GatsbyImage image={treatment.img} alt={treatment.title} />
                        <h4>{treatment.title}</h4>
                    </CLink>
                ))}
            </Article>
        </Fragment>
    );
};

export default OurTreatments;
